<div class="ml-5 mr-5" fxLayout="column">
  <div fxLayout style="align-items: center !important;" class="pageTitle">
    <h5 class="card-title ml-1 font-family-NB mt-4" fxLayout fxFlex="97">Lead Details</h5>
  </div>
  <section class="preview-header-main" *ngIf="currentInsuranceType">
    <div class="preview-header">
      <div class="preview-header-left">
        <div class="home-image">
          <img class="home-image" alt="Angular Logo" [src]="currentInsuranceImage" />
        </div>
        <div class="home-text">
          <h1>
            <span class="spanPrimary">{{currentInsuranceType}}</span> Insurance
          </h1>
        </div>
      </div>
    </div>
    <div class="d-inline-block card">
      <div class="tab preview-tab" [class.tabActive]="currentActiveTab == 'preview'" (click)="toggleTab('preview')">Lead
        Details</div>
      <div class="tab quotes-tab" [class.tabActive]="currentActiveTab == 'quote'" (click)="toggleTab('quote')">Quotes
      </div>
    </div>
  </section>
  <div class="col-md-12 lead-details-parent font-family-NSB">
    <ng-container *ngIf="currentActiveTab == 'preview'">
      <div *ngIf="currentInsuranceType && leadData.length;">
        <div *ngFor="let section of leadData">
          <mat-accordion class="mb-3 card" *ngIf="!section.card_condition || section.isConditionCard">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h5 class="font-family-NB m-0">{{section.card_title}}</h5>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="card cardWrapper">
                <div class="card-body p-0" *ngIf="section.card_questions && section.card_questions.length; else noData">
                  <div class="question" *ngFor="let que of section.card_questions"
                    [ngClass]="{'questionMulti': que.field_type === 'conditional_multi_entry_2' || que.field_type === 'conditional_multi_entry' || que.field_type === 'conditional_entry', 'hideQuestion' : que.isQuestionHide || !(que?.answer?.choice?.length > 0 || que?.answer?.length > 0)}">
                    <ng-container
                      *ngIf="que.field_type === 'conditional_multi_entry_2' || que.field_type === 'conditional_multi_entry' || que.field_type === 'conditional_entry'; then multiEntry else allQuestion">
                    </ng-container>
                    <ng-template #multiEntry>
                      <span class="m-0">{{que.question_text}}</span>
                      <span class="answer">{{que.answer != "" ? que.answer :
                        'N/A'}}</span>
                      <div class="card cardWrapper multi-entry-card position-relative"
                        *ngIf="que.dependent_answers && que.dependent_answers.length;">
                        <div class="nested-que" *ngFor="let depAnsQue of que.dependent_answers; let i = index"
                          [ngClass]="{'dependentMultiQuestion': que.dependent_answers && que.dependent_answers.length - 1 !== i, 'pt-15': que.dependent_answers.length > 1 && que.dependent_answers.length - 1 === i}">
                          <div class="card-body p-0" *ngIf="depAnsQue && depAnsQue.length;">
                            <div class="question" *ngFor="let nestedQus of depAnsQue"
                              [ngClass]="{'questionMulti': nestedQus.dependent_answers.length > 0, 'hideQuestion':nestedQus.isQuestionHide || !(nestedQus?.answer?.choice?.length > 0 || nestedQus?.answer?.length > 0)}">
                              <ng-container
                                *ngIf="nestedQus.dependent_answers.length > 0; then nestedMultiEntry else withoutNested">
                              </ng-container>
                              <ng-template #nestedMultiEntry>
                                <span class="m-0">{{nestedQus.question_text}}</span>
                                <span class="answer">{{nestedQus.answer != "" ? nestedQus.answer :
                                  'N/A'}}</span>
                                <div class="card cardWrapper multi-entry-card"
                                  *ngIf="nestedQus.dependent_answers && nestedQus.dependent_answers.length;">
                                  <div class="nested-que"
                                    *ngFor="let depAnsQue of nestedQus.dependent_answers; let nestedIndex = index"
                                    [ngClass]="{'dependentMultiQuestion': nestedQus.dependent_answers && nestedQus.dependent_answers.length - 1 !== nestedIndex, 'pt-15': nestedQus.dependent_answers.length > 1 && nestedQus.dependent_answers.length - 1 === nestedIndex}">
                                    <div class="card-body p-0" *ngIf="depAnsQue && depAnsQue.length;">
                                      <div class="question" *ngFor="let nestedQus of depAnsQue"
                                        [ngClass]="{'hideQuestion':nestedQus.isQuestionHide || !(nestedQus.answer.choice.length > 0 || nestedQus.answer.length > 0)}">
                                        <span class="m-0">{{nestedQus.question_text}} : </span>
                                        <span class="answer">{{nestedQus.answer != "" ? nestedQus.answer :
                                          'N/A'}}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </ng-template>
                              <ng-template #withoutNested> <span class="m-0">{{nestedQus.question_text}} : </span>
                                <span class="answer">{{nestedQus.answer != "" ? nestedQus.answer :
                                  'N/A'}}</span></ng-template>
                            </div>
                          </div>
                          <div class="multiEntryCardTitle" *ngIf="que?.dependent_card_title?.length > 0;">
                            {{que?.dependent_card_title}} {{i + 1}}
                          </div>
                        </div>
                      </div>
                    </ng-template>
                    <ng-template #allQuestion> <span class="m-0">{{que.question_text}} : </span>
                      <ng-container [ngSwitch]="que.field_type">
                        <span class="answer" *ngSwitchCase="'radio_icon'">
                          <div class="d-flex flex-direction-column align-items-center">
                            <div class="roofOptionImg">
                              <img [src]="que.answer.icon" alt="option?.choice" />
                            </div>
                            <p>{{que.answer?.choice}}</p>
                          </div>
                        </span>
                        <span class="answer" *ngSwitchCase="'checkbox_icon'">
                          <div class=" roofwrapper">
                            <div class="d-flex flex-direction-column align-items-center"
                              *ngFor="let option of que.answer">
                              <div class="roofOptionImg">
                                <img [src]="option.icon" alt="option?.choice" />
                              </div>
                              <p>{{option?.choice}}</p>
                            </div>
                          </div>
                        </span>
                        <span class="answer" *ngSwitchCase="'conditional_checkbox_icon'">
                          <div class="roofwrapper">
                            <div class="d-flex flex-direction-column align-items-center"
                              *ngFor="let option of que.answer">
                              <div class="roofOptionImg">
                                <img [src]="option.icon" alt="option?.choice" />
                              </div>
                              <p>{{option?.choice}}</p>
                            </div>
                          </div>
                        </span>

                        <span class="answer" *ngSwitchCase="'address'">{{ que.answer &&
                          que.answer.split(",")?.length > 0 &&
                          que.answer.split(",")[0] }}</span>
                        <span class="answer" *ngSwitchDefault>{{ que.answer }}</span>

                      </ng-container>
                    </ng-template>
                  </div>
                </div>
              </div>
              <ng-template #noData>
                <h5 class="text-center">No data.</h5>
              </ng-template>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
      <div *ngIf="currentInsuranceType && leadData.length == 0;">
        <h4 class="text-center mt-4"> No data found.!</h4>
      </div>
    </ng-container>
    <ng-container *ngIf="currentActiveTab == 'quote'">
      <!--      quote-card-purchased-->
      <!--      -->
      <div class="quote-card"
        [ngClass]="{'quote-card-accepted': quote.status === 'accepted', 'quote-card-purchased' : quote.status === 'purchased' }"
        *ngFor="let quote of quotes">
        <div class="d-flex justify-content-between align-items-center">
          <h4>{{quote.first_name + ' ' +quote.last_name}}</h4>
          <div class="quote-card-prise">
            ${{quote.total_amount}}
          </div>
        </div>
        <div class="quote-card-details">
          <div class="d-flex align-items-center">
            <div class="quote-card-label">Carrier Name</div>
            <div class="quote-card-label-detail">
              {{quote.insurance_carrier}}
            </div>
          </div>
          <div class="d-flex align-items-center">
            <div class="quote-card-label">Term</div>
            <div class="quote-card-label-detail">
              {{quote.term + ' '+ quote.term_measure}}
            </div>
          </div>
          <div class="d-flex align-items-center">
            <div class="quote-card-label">Quote Validity</div>
            <div class="quote-card-label-detail">
              {{quote.validity + ' '+ quote.validity_measure}}
            </div>
          </div>
        </div>
        <div class="quote-card-document">
          <div class="quote-card-label">Document</div>
          <div class="document-footer">
            <div class="" *ngFor="let doc of quote.documents_loc">
              <a [href]="doc" target="_blank">
                <img src="./assets/img/document.svg" alt="document" />
              </a>
            </div>
            <div *ngIf="quote.documents_loc && quote.documents_loc.length == 0;">
              <span class="">No docs found.!</span>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="currentInsuranceType && quotes.length == 0;">
        <h4 class="text-center mt-4"> No quotes found.!</h4>
      </div>
    </ng-container>
  </div>
</div>