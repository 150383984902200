import {common} from './common'

export const environment = {
  production: true,
  ...common,
  iFrameURL: "http://localhost:3000/",  // change this URL to live react-chat-module URL.
  // S3 bucket https://simba-insurance-images.s3.us-west-2.amazonaws.com/
  firebase: {
    apiKey: "AIzaSyAb0Adpi3AdtvDh3nK0NY8GNh8IOrhyxrY",
    authDomain: "yoloh-prod-e4bb9.firebaseapp.com",
    projectId: "yoloh-prod-e4bb9",
    storageBucket: "yoloh-prod-e4bb9.appspot.com",
    messagingSenderId: "888083623685",
    appId: "1:888083623685:web:6bafe9009a12a1d6a6116c",
    measurementId: "G-NV513T16WC",
    databaseURL: 'https://vision-api-1fda5-default-rtdb.firebaseio.com',
    locationId: 'us-central'
  },
  firebaseUsersCollection: "prod_users",
  cognito: {
    identityPoolId:'us-west-2:792da708-7c85-41e6-ad20-6dbb022acf5c',
    userPoolId: 'us-west-2_04gGJddV2',
    userPoolWebClientId: '3td9ku26f4jgsjprrdt5s5qf9r', //=> agentClientId, we had to use webClientId as per the library
  },
  aws: {
    projectRegion: "us-east-1",
    appSyncGraphQLEndpoint: "https://glieq7uq3be3jh3wnrjcwabn6u.appsync-api.us-east-1.amazonaws.com/graphql",
    service: 'execute-api',
    region: 'us-west-2'
  },
  api_endpoint: "https://963761dyh7.execute-api.us-west-2.amazonaws.com/prod/web",
}

